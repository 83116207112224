(function ($) {
    'use strict';

    $.stickyheader = new Object({
        _scrollPos: 0,
        _elem: $('header#header'),

        init: function () {
            var _elem = $('header#header');
            var lastScrollTop = 0;
            var delta = 5;
            var navbarHeight = _elem.outerHeight();
            var that = this;

            $('body').removeClass('nav-up').addClass('nav-down');

            $(window).scroll(function (event) {
                var st = $(this).scrollTop();

                // Make sure they scroll more than delta
                if (Math.abs(lastScrollTop - st) <= delta) {
                    return;
                }

                // If they scrolled down and are past the navbar, add class .nav-up.
                // This is necessary so you never see what is "behind" the navbar.
                if (st > lastScrollTop && st > navbarHeight) {
                    // Scroll Down
                    $('body').removeClass('nav-down').addClass('nav-up');
                } else {
                    // Scroll Up
                    if (st + $(window).height() < $(document).height()) {
                        $('body').removeClass('nav-up').addClass('nav-down');
                    }
                }

                lastScrollTop = st;
                that._scrollPos = st;
            });

            // remove auto reappear after 8 sec because of new sticky contact button
            // this.checkScroll();
        },

        checkScroll: function () {
            var that = this;
            var _newScrollPos = 0;
            setTimeout(check, 8000);

            function check() {
                if (that._scrollPos > 0) {
                    if (_newScrollPos !== that._scrollPos) {
                        that._elem.removeClass('nav-up').addClass('nav-down');
                    }
                }

                _newScrollPos = $(document).scrollTop();
                setTimeout(check, 8000);
            }
        }
    });
})(jQuery);
