(function ($) {
    'use strict';

    $.youTube = new Object({
        init: function () {
            $('[data-youtube]').each(function () {
                var _clickElement = $(this).find('.youtube-playbutton'),
                    that = $(this);

                _clickElement.click(function (e) {
                    e.preventDefault();
                    var _data = that.data('youtube'),
                        _elem = $('<iframe />').attr('src', _data.src).attr('allow', 'autoplay');

                    that.parent().parent().find('.youtube-overlay').remove();
                    that.html(_elem);
                });
            });
        }
    });
})(jQuery);
