(function ($) {
    'use strict';

    $.randomize = new Object({
        init: function () {
            var _wrap = $('[data-randomize]'),
                _elements = $(_wrap.data('randomize-selector'), _wrap);

            for (var i = _elements.length; i >= 0; i--) {
                _wrap.append(_elements.eq((Math.random() * i) | 0));
            }
        }
    });
})(jQuery);
