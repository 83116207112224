(function ($) {
    'use strict';

    $.navigation = new Object({
        init: function () {
            var clickHandler = function (e) {
                e.preventDefault();
                $('body').toggleClass('no-scroll');
                $(this).toggleClass('is-active');
                $(this).attr('aria-expanded', function (_, attr) {
                    return attr === 'false' ? 'true' : 'false';
                });
                $('.navigation-wrapper').toggleClass('open');

                if ($('.navigation-wrapper').hasClass('open')) {
                    $('.mainlogo, .skip-navigation, main, footer').prop('inert', true);
                } else {
                    $('.mainlogo, .skip-navigation, main, footer').removeAttr('inert');
                    closeSubmenus();
                    setInert(false, false, true);
                }
            };

            $('.navigation-main-submenu-level-1').each(function () {
                $(this).get(0).style.setProperty('--items-count', $(this).data('items-count'));
            });

            var submenuHandler = function (menu, state) {
                var isThirdLevel = menu !== undefined ? menu.hasClass('navigation-main-submenu-level-2') : true;

                closeSubmenus(menu, state);
                $('.navigation-wrapper').scrollTop(0);

                if (state) {
                    menu.addClass('open');
                } else {
                    menu.removeClass('open');
                }

                menu.prev().find('.navigation-main-toggle-submenu').attr('aria-expanded', state);
                menu.find('.navigation-main-close-submenu').attr('aria-expanded', state);
                setInert(state, isThirdLevel, false);
            };

            $('.navigation-main-toggle-submenu').on('click', function () {
                submenuHandler($(this).parent().next(), $(this).attr('aria-expanded') !== 'true');
            });

            $('.navigation-main-close-submenu').on('click', function () {
                submenuHandler($(this).parent().parent(), false);
            });

            var closeSubmenus = function (menu) {
                var isFirstLevel = menu !== undefined ? menu.hasClass('navigation-main-submenu-level-1') : true;
                var submenus = isFirstLevel
                    ? $('.navigation-main-submenu-level-1.open, .navigation-main-submenu-level-2.open')
                    : $('.navigation-main-submenu-level-2.open');

                submenus.each(function () {
                    $(this).removeClass('open');
                    $(this).prev().find('.navigation-main-toggle-submenu').attr('aria-expanded', false);
                    $(this).find('.navigation-main-close-submenu').attr('aria-expanded', false);
                });
            };

            var setInert = function (state, levelThree, all) {
                var levelThreeElements = $(
                    '.mainlogo, .navigation-main > ul > li > .navigation-main-submenu__item > a, .navigation-main > ul > li > .navigation-main-submenu__item > button, .navigation-main-submenu-level-1.open > .navigation-main-submenu__inner > button, .navigation-main-submenu-level-1.open > .navigation-main-submenu__inner > ul > li > .navigation-main-submenu__item > a, .navigation-main-submenu-level-1.open > .navigation-main-submenu__inner > ul > li > .navigation-main-submenu__item > button, .navigation-meta, .navigation-socials'
                );
                var levelTwoElements = $(
                    '.mainlogo, .navigation-main > ul > li > .navigation-main-submenu__item > a, .navigation-main > ul > li > .navigation-main-submenu__item > button, .navigation-meta, .navigation-socials'
                );
                var allInertElements = $('header [inert]');

                if ($(window).width() < 1200) {
                    allInertElements.each(function () {
                        $(this).removeAttr('inert');
                    });

                    if (all) {
                        return;
                    }

                    if (levelThree) {
                        if (state) {
                            levelThreeElements.each(function () {
                                $(this).prop('inert', true);
                            });
                        } else {
                            levelTwoElements.each(function () {
                                $(this).prop('inert', true);
                            });
                        }
                    } else {
                        levelTwoElements.each(function () {
                            if (state) {
                                $(this).prop('inert', true);
                            } else {
                                $(this).removeAttr('inert');
                            }
                        });
                    }
                } else {
                    if (state && !levelThree) {
                        $('.skip-navigation, main, footer').prop('inert', true);
                    }

                    if (!state && !levelThree) {
                        $('.skip-navigation, main, footer').removeAttr('inert');
                    }
                }
            };

            var closeAll = function () {
                $('body').removeClass('no-scroll');
                $('.navigation-wrapper').removeClass('open');
                $('header .hamburger').removeClass('is-active').attr('aria-expanded', 'false');
                $('.mainlogo, .skip-navigation, main, footer').removeAttr('inert');
                closeSubmenus();
                setInert(false, false, true);
            };

            $('header .hamburger').on('click', clickHandler);

            $(document).keyup(function (e) {
                if (e.key === 'Escape') {
                    closeAll();
                }
            });

            $(window).on('resize', function () {
                closeAll();
            });

            $('body').click(function (e) {
                if (!$(e.target).closest('header').length && !$(e.target).is('header')) {
                    closeAll();
                }
            });

            $.fn.isInViewport = function () {
                var elementTop = $(this).offset().top;
                var elementBottom = elementTop + $(this).outerHeight();

                var viewportTop = $(window).scrollTop();
                var viewportBottom = viewportTop + $(window).height();

                return elementBottom > viewportTop && elementTop < viewportBottom;
            };

            if ($('.theme_intro_video .hero-video__top').length) {
                $(window).on('resize scroll', function () {
                    if ($('.theme_intro_video .hero-video__top').isInViewport()) {
                        $('header#header').removeClass('scrolled-by');
                    } else {
                        $('header#header').addClass('scrolled-by');
                    }
                });
            }
        }
    });
})(jQuery);
