(function ($) {
    'use strict';

    $.newsletter = new Object({
        init: function () {
            if (!$.cookie('supi')) {
                return void 0;
            }

            var _modal = $('#newsletterModal');

            _modal.on('shown.bs.modal', function (event) {
                var _input = event.target.querySelector('input[type="email"]');
                _input.focus();
            });

            _modal.on('hidden.bs.modal', function (event) {
                document.querySelector('#header .mainlogo').focus();
            });

            _modal.on('hide.bs.modal', function (event) {
                $('body').removeClass('no-scroll');
                $.cookie('hideoverlay', 1, { expires: 7, path: '/' });
            });

            $("form[id^='newsletter_register-']").each(function () {
                var that = $(this);
                var id = that.attr('id').match(/(\d+)$/);
                var selector = "input[id='newsletter_register-" + id[1] + "-url']";
                $(selector).val(window.location.href);
            });

            if (!$.cookie('hideoverlay') && _modal.length > 0) {
                setTimeout(function () {
                    _modal.modal('show');
                }, 5000);

                $('#newsletterModal form').on('submit', function () {
                    $.cookie('hideoverlay', 1, { expires: 7, path: '/' });
                });
            }
        }
    });
})(jQuery);
