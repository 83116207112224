(function ($) {
    'use strict';

    /**
     * animation wrapper class
     *
     * use data-animation attribute in html element
     * to set animations.
     *
     * example:
     *
     * <div data-animation='{ "--translateY": { "from": "0", "to": "100px" } }'>
     *      <div>erste box</div>
     * </div>
     *
     * the css variable defines, what should be animated. possible values at the moment
     * are all transform options eg.:
     *
     * --translateY
     * --translateX
     *
     * and
     *
     * --opacity
     *
     * you can use positive and negative values
     * from: "-10px"
     * to: "100px"
     *
     * the main scss file _animation.scss defines the default css variables and what should be
     * animated. extend on your needs.
     *
     * @type {Object}
     */
    $.animation = new Object({
        // the array where we iterate the elements to start the animation
        _animate: [],

        // the element selector (i used jquery to due laziness)
        _elements: $('[data-animation]'),

        // the init function is called in the main.js file here starts the magic
        init: function () {
            // return and do nothing, if the current client is an ie/edge
            if (
                $.browserDetect.getBrowser().indexOf('IE') !== -1 ||
                $.browserDetect.getBrowser().indexOf('Edge') !== -1
            ) {
                return;
            }

            this.start();
            var that = this;

            if ($.breakpoints.breakpointDown('md')) {
                $('html').addClass('anim-stop');
                that.stop();
            }

            $(window).on('resize', function () {
                if ($.breakpoints.breakpointDown('md')) {
                    $('html').addClass('anim-stop');
                    that.stop();
                } else {
                    if ($('html').hasClass('anim-stop')) {
                        that.start();
                        $('html').removeClass('anim-stop');
                    } else {
                        that.update();
                    }
                }
            });
        },

        // create the basicScroll animation object for each iterated element
        // the properties will come from the data-attribute
        // it is possible to set callbacks for react on beein inside or outside the
        // viewport
        create: function (_element) {
            return basicScroll.create({
                elem: _element[0],
                from: 'top-bottom',
                to: 'top-middle',
                direct: true,
                props: _element.data('animation')
                // inside: function(instance, percentage, props) {
                // },
                // outside: function(instance, percentage, props) {
                // }
            });
        },

        // iterate through all elements and start the animation
        start: function () {
            var that = this;

            this._elements.each(function () {
                if (that.checkConfig($(this).data('animation')) === true) {
                    that._animate.push(that.create($(this)));
                }
            });

            $.each(this._animate, function (key, element) {
                element.start();
            });
        },

        // stops the animation and removes all style attributes to set the initial
        // values for each element
        stop: function () {
            $.each(this._animate, function (key, element) {
                element.destroy();
            });

            this._elements.removeAttr('style');
        },

        // update function, if window is resized
        update: function () {
            $.each(this._animate, function (key, element) {
                element.calculate();
                element.update();
            });
        },

        // checks the config of the animated element
        checkConfig: function (config) {
            var _return = true;

            $.each(config, function (k, v) {
                if (v.from === '' || v.to === '') {
                    _return = false;
                }
            });

            return _return;
        }
    });
})(jQuery);
