(function ($) {
    'use strict';

    $.lazyLoading = new Object({
        init: function () {
            var body = $('body');
            var images = $('[loading="lazy"]');

            var observer = new IntersectionObserver(function (entries, o) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        entry.target.removeAttribute('loading');

                        if (body.hasClass('develop')) {
                            console.log({
                                message: 'removed loading attribute',
                                element: entry.target
                            });
                        }
                    }
                });

                setTimeout(function () {
                    o.disconnect();
                }, 200);
            });

            images.each(function (k, image) {
                observer.observe(image);
            });
        }
    });
})(jQuery);
